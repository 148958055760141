<script>
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';

const store = {
  state: {
    student: {
      id: 0,
      name: 'Student',
      answers: [{
        title: 'dsffds',
      }],
    },
  },
};
export default {
  name: 'ManagementTestStudentResultsPage',
  data() {
    return {
      store,
      model: {},
    };
  },
  methods: {
    ListStudents() {
      const listStudPath = '/WebOpenaiUserFileEvaluation.php';
      const data = new FormData();
      data.append('file_id', this.$route.params.file_id);
      data.append('user_id', this.$route.params.student_id);
      if (this.model.search !== undefined && this.model.search !== '') {
        data.append('filter', this.model.search);
      }
      fetch(server + listStudPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong authorization token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json) {
            store.state.student = json;
          }
        });
    },
  },
  beforeMount() {
    this.ListStudents();
  },
};
</script>
<template>
  <div class="main-select-tests main-select-tests2">
    <b-table-simple class="custom-table">
      <b-thead class="first-thead">
        <b-tr>
          <td style="width: 6%;">
            <div class="back" v-on:click="$router.go(-1)">
              <unicon name="angle-left-b" y="-6"
                      width="18" height="18" fill="#828282"/>
            </div>
          </td>
          <td style="width: 94%;" colspan="2">
            <div class="user-details">
              <div class="d-inline-flex">
                <div class="user-image">
                  <img :src="store.state.student.user_image ? store.state.student.user_image:
                    '/media/user.jpg'" alt="" />
                </div>
                <div class="item-info">
                  <div style="width: 100%">
                    <div style="overflow: hidden;height: 24px;
                    text-overflow: ellipsis">
                      <unicon height="20" width="20" name="user-circle"
                              fill="#FDAB3D"></unicon>
                      {{ store.state.student.user_name }}
                    </div>
<!--                    <div class="contains">
                      <div class="ellipse green"></div>
                      <div class="caption-2-reg-12 basic-2">
                      </div>
                    </div>-->
                  </div>
                </div>
              </div>
            </div>
          </td>
        </b-tr>
      </b-thead>
      <b-thead>
        <b-tr>
          <b-th style="width: 6%;">
            {{ $t("DEPARTAMENTS.NO") }}
          </b-th>
          <b-th style="width: 66%;">
            {{ $t("GENERAL.QUESTION") }}
          </b-th>
          <b-th style="width: 30%;">
            {{ $t("FILE_AI.PERCENT_SUCCESSFUL") }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(item,i) in store.state.student.evaluations" :key="i">
          <b-td  class="item-no" style="width: 6%;">
            {{ i+1 }}
          </b-td>
          <b-td  style="width: 66%;">
            <div class="user-details" style="align-items:center ;display: flex">
              <div class="d-inline-flex">
                <div class="item-info d-inline-flex">
                  <div style="width: 100%">
                    <div style="overflow: hidden;height: 24px;
                    text-overflow: ellipsis"
                         class="headline-medium-16 basic-1">
                      {{item.name}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-td>
          <b-td style="width: 30%;padding-top: 20px;font-weight: 700;font-size: 14px;
              line-height: 17px;" class="basic-1">
            <div>
              <div class="basic-1" style="font-weight: 700;font-size: 14px;line-height: 17px;
                  margin-bottom: 4px">
                {{item.percent}}%
              </div>
              <b-progress :max="100">
                <b-progress-bar :value="item.percent" variant="success">
                </b-progress-bar>
                <b-progress-bar :value="100 - item.percent"
                                variant="danger">
                </b-progress-bar>
              </b-progress>
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<style>
.main-select-tests2  tbody tr:hover,.main-select-tests2  tbody tr:hover{
  background-color: #FFF;
  cursor: unset;
}
.main-select-tests2 tbody tr:hover td,.main-select-tests2 tbody tr:hover td{
  background-color: #FFF;
}
</style>
